<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-form
        ref="form"
        v-model="valid"
      >

        <v-row v-if="type === 'FRONTEND'" wrap no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="values.setPasswordUrl"
              outlined
              dense
              :label="$lang.labels.setPasswordUrl"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="values.baseUrlRest"
              outlined
              dense
              :label="$lang.labels.baseUrlRest"
              required
              :rules="[v => !!v || $lang.labels.required]"
              :readonly="!canEdit"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="values.defaultLocale"
              :items="locales"
              :label="$lang.labels.defaultLocale"
              outlined
              dense
              class="required-asterisk"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="values.logServerUrl"
              outlined
              dense
              :label="$lang.labels.logServerUrl"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="values.metricServerUrl"
              outlined
              dense
              :label="$lang.labels.metricServerUrl"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="values.visualisationServerUrl"
              outlined
              dense
              :label="$lang.labels.visualisationServerUrl"
              :readonly="!canEdit"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type === 'INSTANCE_PROCESSING'" wrap no-gutters>
          <v-col cols="12" md="8" class="pr-sm-1">
            <v-row wrap no-gutters>
              <v-col cols="12" md="6" class="pr-md-1">
                <v-text-field
                  v-model="values.maxProcessingThreads"
                  outlined
                  dense
                  :label="$lang.labels.maxProcessingThreads"
                  required
                  :rules="[v => !!v || $lang.labels.required]"
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pl-md-1">
                <v-text-field
                  v-model="values.maxTriggerHttpThreads"
                  outlined
                  dense
                  :label="$lang.labels.maxTriggerHttpThreads"
                  required
                  :rules="[v => !!v || $lang.labels.required]"
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="8" md="6" class="pr-1">
                <v-text-field
                  v-model="tempMaxTriggerHttpProcessingInMSec"
                  outlined
                  dense
                  :label="$lang.labels.maxTriggerHttpProcessingInMSec"
                  required
                  :rules="[v => !!v || $lang.labels.required, () => (values.maxTriggerHttpProcessingInMSec <= 3600000) || 'Max processing value is to great!']"
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="4" md="6" class="pl-1">
                <v-select
                  v-model="maxTriggerHttpProcessingInMSecUnit"
                  :items="timeUnits"
                  :label="$lang.labels.unit"
                  outlined
                  dense
                  required
                  :readonly="!canEdit"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="pr-1">
                <v-text-field
                  v-model="values.maxEmailSendingThreads"
                  outlined
                  dense
                  :label="$lang.labels.maxEmailSendingThreads"
                  required
                  :rules="[v => !!v || $lang.labels.required, () => (values.maxEmailSendingThreads <= 3600000) || 'Max processing value is to great!']"
                  :readonly="!canEdit"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="type === 'PROCESS'" wrap no-gutters class="pb-4">
          <v-col cols="12">
            <add-key-value
              type="values"
              :can-edit="canEdit"
              :data="processValues"
              @dataChanged="processValues = $event; $emit('setValues', formatValues())"
            ></add-key-value>
          </v-col>
        </v-row>

        <v-row v-if="type === 'PLUGIN'" wrap no-gutters class="pb-4">
          <v-col cols="12">
            <add-key-value
              type="values"
              :can-edit="canEdit"
              :data="pluginValues"
              @dataChanged="pluginValues = $event; $emit('setValues', formatValues())"
            ></add-key-value>
          </v-col>
        </v-row>

      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { getProcessCredentialsUsingGET as getCredentials } from '@/utils/api'
import {
  predictBestTimeUnitFromMsValue
} from '@/utils/helpers'
import addKeyValue from './KeyValPairModal'
import { mapActions } from 'vuex'

export default {
  components: {
    addKeyValue
  },
  props: {
    type: {
      type: String,
      default: () => ''
    },
    isEdit: {
      type: Boolean,
      default: () => false
    },
    isRestore: {
      type: Boolean,
      default: () => false
    },
    defaultValues: {
      type: Object,
      default: () => {}
    },
    canEdit: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      valid: false,
      values: {
        host: '',
        password: '',
        port: '',
        username: '',
        connectionTimeout: '10000',
        timeout: '10000',
        writeTimeout: '10000',
        setPasswordUrl: '',
        baseUrlRest: '',
        defaultLocale: '',
        maxProcessingThreads: 0,
        maxTriggerHttpProcessingInMSec: 0,
        maxTriggerHttpThreads: 0,
        maxEmailSendingThreads: 0,
        name: '',
        system: false,
        logServerUrl: '',
        metricServerUrl: '',
        visualisationServerUrl: ''
      },
      processValues: {},
      pluginValues: {},
      isLoadingCredentials: false,
      credentials: [],
      searchCredentials: '',
      locales: [
        { text: this.$lang.labels.bg, value: 'bg' },
        { text: this.$lang.labels.zh, value: 'zh' },
        { text: this.$lang.labels.hr, value: 'hr' },
        { text: this.$lang.labels.cs, value: 'cs' },
        { text: this.$lang.labels.da, value: 'da' },
        { text: this.$lang.labels.nl, value: 'nl' },
        { text: this.$lang.labels.en, value: 'en' },
        { text: this.$lang.labels.et, value: 'et' },
        { text: this.$lang.labels.fi, value: 'fi' },
        { text: this.$lang.labels.fr, value: 'fr' },
        { text: this.$lang.labels.de, value: 'de' },
        { text: this.$lang.labels.el, value: 'el' },
        { text: this.$lang.labels.hu, value: 'hu' },
        { text: this.$lang.labels.ga, value: 'ga' },
        { text: this.$lang.labels.it, value: 'it' },
        { text: this.$lang.labels.lv, value: 'lv' },
        { text: this.$lang.labels.lt, value: 'lt' },
        { text: this.$lang.labels.mt, value: 'mt' },
        { text: this.$lang.labels.pl, value: 'pl' },
        { text: this.$lang.labels.pt, value: 'pt' },
        { text: this.$lang.labels.ro, value: 'ro' },
        { text: this.$lang.labels.ru, value: 'ru' },
        { text: this.$lang.labels.sr, value: 'sr' },
        { text: this.$lang.labels.sk, value: 'sk' },
        { text: this.$lang.labels.sl, value: 'sl' },
        { text: this.$lang.labels.es, value: 'es' },
        { text: this.$lang.labels.sv, value: 'sv' },
        { text: this.$lang.labels.tr, value: 'tr' },
        { text: this.$lang.labels.uk, value: 'uk' },
        { text: this.$lang.labels.notSet, value: '' }
      ],
      tempMaxTriggerHttpProcessingInMSec: 0,
      maxTriggerHttpProcessingInMSecUnit: '',
      timeUnits: ['MSec', 'Sec', 'Min', 'Hr']
    }
  },
  watch: {
    valid: {
      handler (val) {
        this.$emit('setValues', val ? this.formatValues() : null)
      },
      deep: true
    },
    defaultValues: {
      async handler (val) {
        if (val && !['PROCESS', 'PLUGIN'].includes(this.type)) this.values = this.defaultValues

        if (this.values && this.values.maxTriggerHttpProcessingInMSec !== null && !['PROCESS', 'PLUGIN'].includes(this.type)) {
          if (!this.values.maxEmailSendingThreads) this.values.maxEmailSendingThreads = 0
          const unitMaxTriggerHttpProcessing = await predictBestTimeUnitFromMsValue(this.values.maxTriggerHttpProcessingInMSec)

          this.maxTriggerHttpProcessingInMSecUnit = unitMaxTriggerHttpProcessing.unit
          this.tempMaxTriggerHttpProcessingInMSec = unitMaxTriggerHttpProcessing.value
        }

        if (val && this.type === 'PROCESS') {
          this.processValues = this.defaultValues
        }
        if (val && this.type === 'PLUGIN') {
          this.pluginValues = this.defaultValues
        }
      },
      deep: true
    },
    searchCredentials: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchCredentialsFunction(val)
        }
      }
    },
    maxTriggerHttpProcessingInMSecUnit: {
      handler(val) {
        if (val) {
          this.values.maxTriggerHttpProcessingInMSec = this.$options.filters.unitFormatter(this.tempMaxTriggerHttpProcessingInMSec, val, 'MSec')
        }
      }
    },
    tempMaxTriggerHttpProcessingInMSec: {
      handler(val) {
        if (val) {
          this.values.maxTriggerHttpProcessingInMSec = this.$options.filters.unitFormatter(this.tempMaxTriggerHttpProcessingInMSec, this.maxTriggerHttpProcessingInMSecUnit, 'MSec')
        }
      }
    }
  },
  async created() {
    if ((this.isEdit || this.isRestore) && this.defaultValues && !['PROCESS', 'PLUGIN'].includes(this.type)) this.values = this.defaultValues

    if (this.values && this.values.maxTriggerHttpProcessingInMSec !== null && !['PROCESS', 'PLUGIN'].includes(this.type)) {
      if (!this.values.maxEmailSendingThreads) this.values.maxEmailSendingThreads = 0

      const unitMaxTriggerHttpProcessing = await predictBestTimeUnitFromMsValue(this.values.maxTriggerHttpProcessingInMSec)

      this.maxTriggerHttpProcessingInMSecUnit = unitMaxTriggerHttpProcessing.unit
      this.tempMaxTriggerHttpProcessingInMSec = unitMaxTriggerHttpProcessing.value
    }

    if (this.type === 'SMTP') {
      this.searchCredentials = this.values.name
    }

    if (this.type === 'PROCESS') {
      this.processValues = this.defaultValues
    }

    if (this.type === 'PLUGIN') {
      this.pluginValues = this.defaultValues
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    searchCredentialsFunction(val = '') {
      this.isLoadingCredentials = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      if (this.type) obj.type = this.type

      obj.type = 'SMTP'

      getCredentials(obj)
        .then((res) => {
          this.credentials = res.data.data.items
          this.isLoadingCredentials = false
        })
        .catch((err) => {
          this.isLoadingCredentials = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })        })
    },
    formatValues() {
      const value = this.values

      switch (this.type) {
      case 'FRONTEND':
        return {
          setPasswordUrl: value.setPasswordUrl,
          baseUrlRest: value.baseUrlRest,
          defaultLocale: value.defaultLocale,
          logServerUrl: value.logServerUrl,
          metricServerUrl: value.metricServerUrl,
          visualisationServerUrl: value.visualisationServerUrl
        }
      case 'INSTANCE_PROCESSING':
        return {
          maxProcessingThreads: value.maxProcessingThreads,
          maxTriggerHttpProcessingInMSec: value.maxTriggerHttpProcessingInMSec,
          maxTriggerHttpThreads: value.maxTriggerHttpThreads,
          maxEmailSendingThreads: value.maxEmailSendingThreads
        }
      case 'PROCESS':
        return this.processValues
      case 'PLUGIN':
        return this.pluginValues
      default:
        return {}
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.v-input--selection-controls {
  margin-top: 8px !important;
}
</style>
